import Popup from "./popUp.js";

var previousRecipientBar = (function() {
  var setUpSearchBar = function() {
    /*
      pop up
    */

    var el = document.getElementById("side-bar-previous-recipients");

    if (el) {
      var searchBar = Popup.init("side-bar-previous-recipients");
      //console.log(window.searchBar);

      var $searchBarTrigger = $(".js-side-bar-previous-recipients-trigger");
      var $body = $("body");

      searchBar.on("show", function(dialogEl, triggerEl) {
        $body.addClass("js-search-bar-active");
        $searchBarTrigger.addClass("is-active");
        $(dialogEl)
          .find(".c-button--hamburger")
          .addClass("is-active");
      });

      searchBar.on("hide", function(dialogEl, triggerEl) {
        $body.removeClass("js-search-bar-active");
        $searchBarTrigger.removeClass("is-active");
        $(dialogEl)
          .find(".c-button--hamburger")
          .removeClass("is-active");
      });

      $searchBarTrigger.on("click", function(e) {
        e.preventDefault();

        if ($searchBarTrigger.hasClass("is-active")) {
          searchBar.hide();
        } else {
          searchBar.show();
        }
      });

      //searchBar.show();
    }
  };

  var setupProjectDetails = function() {
    $(".js-expand-project-details-trigger").on("click", function(e) {
      e.preventDefault();

      var $this = $(this);

      $this
        .closest(".c-recipients-list__row")
        .toggleClass("is-expanded")
        .attr("aria-expanded", function(i, attr) {
          return attr == "true" ? "false" : "true";
        });
      $this
        .closest(".c-recipients-list__row")
        .find(".c-button--minus")
        .focus();
      $this
        .closest(".c-recipients-list__row")
        .find(".c-button--plus")
        .focus();
    });
  };

  function init() {
    setUpSearchBar();
    setupProjectDetails();
  }

  return {
    init: init
  };
})();

export default previousRecipientBar;
