var accordion = (function () {

  var setupAccordion = function () {
    $('.js-accordion').accordion({ buttonsGeneratedContent: 'html' });
  };

  var init = function () {
    setupAccordion();
  }

  return {
    init: init
  };
})();

export default accordion;
