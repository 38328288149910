import A11yDialog from 'a11y-dialog';
import { TweenLite } from 'gsap';

var popUp = (function () {
  var init = function (elPopUp, onShow) {
    // Get the dialog element (with the accessor method you want)
    var el = document.getElementById(elPopUp);
    // Instantiate a new A11yDialog module
    var containers = document.querySelectorAll('header, #main-content, footer'); // containers that needs to hidden when dialog opens

    var animation;
    var useFade = true;

    if (elPopUp == 'mobile-nav' || $(window).width() < 1200) {
      useFade = true;
    }

    /*$(window).resize(function () {
      if (elPopUp == 'mobile-nav' || $(window).width() < 1200) {
        useFade = true;
      }
    });*/


    var killAllAnimations = function (dialogEl) {
      if (animation) {
        animation.kill();
      }
    };



    var dialog = new A11yDialog(el, containers);


    $(dialog.node).attr('tabindex', '-1');

    dialog.on('show', function (dialogEl, triggerEl) {

      killAllAnimations(dialogEl);

      var showDailog = function () {
        var animationComplete = $.Deferred();
        TweenLite.set($(dialogEl), { display: "block", zIndex: 10 });

        animation = TweenLite.to($(dialogEl), 0.35, {
          opacity: 1,
          onComplete: function () {
            animationComplete.resolve();
          }
        });
        return animationComplete.promise();
      }

      /*
     var slideInContent = function () {

       var animationComplete = $.Deferred();
       TweenLite
         .set($(dialogEl).find('.c-dialog__content'), {
           x: '-100%', opacity: 1, onComplete: function () {
             TweenLite
               .to($(dialogEl).find('.c-dialog__content'), 0.75,
                 {
                   x: '0%', ease: Power2.easeInOut, onComplete: function () {
                     animationComplete.resolve();
                   }
                 });
           }
         });

       return animationComplete.promise();
     }

    
     var fadeInContent = function () {
       var animationComplete = $.Deferred();
 
 
       TweenLite.set($(dialogEl).find('.c-dialog__content'), {
         opacity: 1
       });
 
       animation = TweenLite.to($(dialogEl), 0.3, {
         zIndex: 10,
         opacity: 1,
         ease: Power2.easeInOut
       })
 
 
       // 
 
       return animationComplete.promise();
 
     }
 
   
     var animateIn = function () {
       $('html,body').toggleClass('js-dailog-open');
       showDailog()
         .then(slideInContent)
     }*/

      var fadeIn = function () {
        $('html,body').toggleClass('js-dailog-open');
        showDailog()
          .then(function () {
            animation = null;
          })
      }

      if (useFade) {
        fadeIn();
      } else {
        //animateIn();
      }


    });

    dialog.on('hide', function (dialogEl, triggerEl) {

      killAllAnimations(dialogEl);

      var hideDailog = function () {
        var animationComplete = $.Deferred();

        animation = TweenLite.to($(dialogEl), 0.35, {
          opacity: 0,
          ease: Power2.easeInOut,
          onComplete: function () {
            animationComplete.resolve();
          }
        });
        return animationComplete.promise();
      }

      var sideOutContent = function () {

        var animationComplete = $.Deferred();

        TweenLite
          .to($(dialogEl).find('.c-dialog__content'), 0.75,
            {
              x: '-100%', ease: Power2.easeInOut, onComplete: function () {

                TweenLite
                  .set($(dialogEl).find('.c-dialog__content'), {
                    x: '0', opacity: 0, onComplete: function () {
                      animationComplete.resolve();
                    }
                  });
              }
            });

        return animationComplete.promise();
      }

      /*
      var fadeOutContent = function () {
        var animationComplete = $.Deferred();

        TweenLite
          .to($(dialogEl).find('.c-dialog__content'), 0.75,
            {
              opacity: 0, ease: Power2.easeInOut, onComplete: function () {
                animationComplete.resolve();
              }
            });

        return animationComplete.promise();

      }
     
      function animateOut() {
        $('html,body').toggleClass('js-dailog-open');

        sideOutContent()
          .then(hideDailog)
      };
      */


      var fadeOut = function () {
        $('html,body').toggleClass('js-dailog-open');
        hideDailog()
          // .then(fadeOutContent)
          .then(
            function () {
              TweenLite.set($(dialogEl), {
                display: "none",
                zIndex: -1,
                onComplete: function () {
                  animation = null;
                }
              })
            }
          );
      }

      if (useFade) {
        fadeOut();
      } else {
        //animateOut();
      }




    });

    return dialog;
  };

  return {
    init: init
  };
})();

export default popUp;
