import ScrollToPlugin from "gsap/ScrollToPlugin";
import { TweenLite } from 'gsap';
console.log(ScrollToPlugin.version);
var localScroll = (function() {
  var init = function() {
    //var localLinks = document.querySelectorAll('[data-localscroll]');
    var localLinks = $("[data-localscroll]");
    let myArray = Array.from(localLinks);

    myArray.map(function(item, index) {
      $(item).on("click", function(e) {
        e.preventDefault();

        let localSection = $(this).data("localscroll");
        $(localSection).attr("tabIndex", -1);
        $(this).blur();
        if(localSection == "#main-content"){
          TweenLite.to(window, 0.5, {
            scrollTo: { y: 0 },
            onComplete: function() {
            }
          });
        } else {
          console.log('localSection', localSection)
          TweenLite.to(window, 0.5, {
            scrollTo: { y: localSection }, 
            onComplete: function () {
              $(localSection).focus();
            }
          });
        }
      });
    });
  };

  return {
    init: init
  };
})();

export default localScroll;
