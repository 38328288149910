import debounce from "./debounce";

var keyDatesCarousel = (function() {
  var setUpcarousel = function() {
    var $carousel = $(".js-key-dates-carousel");

    var $carouselContainer = $carousel.find(".js-carousel__container");

    var $carouselDotsContainer = $carousel.find(".js-carousel__dots");
    var $carouselNext = $carousel.find(".c-button--right-arrow");
    var $carouselPrev = $carousel.find(".c-button--left-arrow");

    var settings = {
      dots: true,
      appendDots: $carouselDotsContainer,
      dotsClass: "o-list-inline u-margin-bottom-small",
      prevArrow: $carouselPrev,
      nextArrow: $carouselNext,
      adaptiveHeight: true,
      autoplay: false,
      dots: true,
      accessibility: false,
      mobileFirst: true,
      infinite: false,
      customPaging: function(slider, i) {
        return $('<button type="button" />').text(i + 1);
      },
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    if ($carouselContainer.length) {
      $carouselContainer.slick(settings);
    }
  };

  var init = function() {
    setUpcarousel();
  };

  return {
    init: init
  };
})();

export default keyDatesCarousel;
