import Popup from './popUp.js';

var searchBar = (function () {

  var setUpSearchBar = function () {

    /*
      pop up
    */
    if (!document.getElementById('search-bar')) {
      return;
    }
    var searchBar = Popup.init('search-bar');
    //console.log(window.searchBar);

    var $searchBarTrigger = $('.js-search-bar-trigger');
    var $body = $('body');

    searchBar.on('show', function (dialogEl, triggerEl) {

      $body.addClass('js-search-bar-active');
      $searchBarTrigger.addClass('is-active');
      $(dialogEl).find('.c-button--hamburger').addClass('is-active');

    });

    searchBar.on('hide', function (dialogEl, triggerEl) {

      $body.removeClass('js-search-bar-active');
      $searchBarTrigger.removeClass('is-active');
      $(dialogEl).find('.c-button--hamburger').removeClass('is-active');

    });

    $searchBarTrigger.on('click', function (e) {
      e.preventDefault();

      if ($searchBarTrigger.hasClass('is-active')) {
        searchBar.hide();
      } else {
        searchBar.show();
      }

    });

    //searchBar.show(); 

  };

  function init() {
    setUpSearchBar();
  };


  return {
    init: init
  };
})();

export default searchBar;
