var homepageCarousel = (function() {
  var setupHomepageCarousel = function() {
    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    console.log();

    // var initialSlide = getRandomInt(
    //   0,
    //   $(".c-homepage-banner__carousel-item").length - 1
    // );

    var homePageCarousel = $("#personas")
      .on("init", function() {
        $(".c-homepage-banner").addClass("is-visible");
      })
      .slick({
        arrows: false,
        fade: true,
        swipe: false,
        touchMove: false,
        accesibility: false,
        autoplay: true,
        autoplaySpeed: 7000,
        speed: 1500
      })
      .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        $(document).trigger("homepage:slider:change", nextSlide);
      })
      .on("afterChange", function(event, slick, currentSlide, nextSlide) {});

    $(".c-homepage-banner__search select.js-select-persona").on(
      "change",
      function(e) {
        var $this = $(this);
        var slideIndex = parseInt(
          $this.find("option:selected").data("slide"),
          10
        );
        homePageCarousel.slick("slickGoTo", slideIndex);
      }
    );
    // Pause or play home page banner
    $('.c-homepage-banner__carousel-control').on('click', function() {
        if(!$('#personas').hasClass('c-pause')){
          $('#personas').slick('slickPause');
          $('#personas').addClass('c-pause');
          $(this).addClass('c-pause');
        }else{
          $('#personas').slick('slickPlay');
          $('#personas').removeClass('c-pause');
          $(this).removeClass('c-pause');
        }
    });
  
    // $(document).on("homepage:slider:stopAutoRotate", function() {
    //   console.log("stop autoplay");
    //   homePageCarousel.slick("slickSetOption", "autoplay", false, true);
    // });
  };

  var init = function() {
    setupHomepageCarousel();
  };

  return {
    init: init
  };
})();

export default homepageCarousel;
