import "babel-polyfill";
import SearchBar from "./modules/searchBar";
import CustomSelectBox from "./modules/selectbox";
import KeyDatesCarousel from "./modules/keydatesCarousel";
import PreviousRecipientBar from "./modules/previousRecipientsBar";
import LocalScroll from "./modules/localScroll";
import Accordion from "./modules/accordion";
import HomepageCarousel from "./modules/homepageCarousel";
import Navigation from "../../node_modules/nsw-design-system/src/components/main-nav/main-nav";

(function ($) {
  $("html")
    .removeClass("no-js")
    .addClass("js");
  CustomSelectBox.init();
  HomepageCarousel.init();
  SearchBar.init();
  KeyDatesCarousel.init();
  PreviousRecipientBar.init();
  LocalScroll.init();
  Accordion.init();

  $(".c-button--back").on("click", function (e) {
    e.preventDefault();
    history.go(-1);
  });
  // alert();
  /*$('.c-nsw-logo')
    .on('click', function (e) {
      e.preventDefault();

      $('body').toggleClass('show-grid');

    });

  $('.gird-visual')
    .on('click', function (e) {
      e.preventDefault();

      $('body').toggleClass('show-grid');

    });*/

  $(window).scroll(function () {
    if ($(window).scrollTop() >= 300) {
      $('body').addClass('filter-form-fixed');
    }
    else {
      $('body').removeClass('filter-form-fixed');
    }
  });

  $(function () {
    $('#grant').multiselect({
      columns: 1,
      placeholder: 'Grant',
    });
    $('#disease-group').multiselect({
      columns: 1,
      placeholder: 'Organ System',
    });
    $('#status').multiselect({
      columns: 1,
      placeholder: 'Status',
    });


  });


})(jQuery);


$(document).ready(function () {
  $('.owl-carousel').owlCarousel({
    loop: true,
    items: 1,
    nav: true,
    dotsData: false
  })
});

const navigation = document.getElementById('main-nav');
new Navigation(navigation).init();