var customSelectBox = (function() {
  var $selectBoxes = $(".js-choice-select");
  var label = ".js-choice-label";

  var updateInitialVal = function() {
    $selectBoxes.each(function() {
      this.orginalTxt = $(this)
        .siblings(label)
        .html();
      //console.log($(this).find('option:selected'));

      if ($(this).find("option:selected")) {
        var currentVal = $(this)
          .find("option:selected")
          .val();
        //console.log(currentVal);
        if (!currentVal) {
          $(this)
            .siblings(label)
            .html(this.orginalTxt);
        } else {
          $(this)
            .siblings(label)
            .html(currentVal);
        }
      }
    });
  };

  var setupSelectBox = function() {
    $(".c-form__select-label").on("click", function(e) {
      e.preventDefault();
      $(".js-select-persona")
        .data("selectric")
        .open();
    });
    $selectBoxes
      .on("change", function(e) {
        var $this = $(this);
        $this
          .closest(".c-form__select-wrapper")
          .find(".c-form__select-label")
          .text($this.find("option:selected").text());
      })
      .on("focus", function(e) {
        //$(this).siblings(label).addClass('focused');
        $(this).trigger("change");
      })
      .on("blur", function(e) {
        //$(this).siblings(label).removeClass('focused');
        $(this).trigger("change");
      });
  };

  var setupBootstarpSelect = function() {
    var isTopicOpen = false;
    $(".js-select-topic")
      .selectric({
        optionsItemBuilder: "<span>{text}</span>",
        maxHeight: 500,
        onInit: function onInit(element) {
          var $parent = $(element).closest(".c-form__select-wrapper");
          $parent
            .find(".selectric-input")
            .attr("id", "selectric-input-Topic")
            .wrap(
              "<label for='selectric-input-Topic' class='u-hidden-visually' aria-hidden='true'>Selected Topic</label>"
            );
        }
      })
      .on("selectric-before-open", function(event, element, selectric) {
        isTopicOpen = true;
      })
      .on("selectric-close", function(event, element, selectric) {
        isTopicOpen = false;
      })
      .on("selectric-refresh", function(event, element, selectric) {
        $(element)
          .closest(".selectric-wrapper")
          .find(".label")
          .fadeOut(0);
        // topic label fade out
        $(element)
          .closest(".c-form__select-wrapper")
          .find('.c-form__info')
          .fadeOut(0);
        
        $(element)
          .closest(".selectric-wrapper")
          .find(".label")
          .fadeIn(2000);
        // topic label fade in
        $(element)
          .closest(".c-form__select-wrapper")
          .find('.c-form__info')
          .fadeIn(2000);
      });

    var isPersonaOpen = false;
    $(".js-select-persona")
      .selectric({
        optionsItemBuilder: "<span>{text}</span>",
        maxHeight: 500,
        onInit: function onInit(element) {
          var $parent = $(element).closest(".c-form__select-wrapper");
          $parent
            .find(".selectric-input")
            .attr("id", "selectric-input-persona")
            .wrap(
              "<label for='selectric-input-persona' class='u-hidden-visually' aria-hidden='true'>Selected persona</label>"
            );
        }
      })
      .on("selectric-before-open", function(event, element, selectric) {
        $(document).trigger("homepage:slider:stopAutoRotate");
      })
      .on("selectric-before-open", function(event, element, selectric) {
        $(document).trigger("homepage:slider:stopAutoRotate");
      })
      .on("selectric-close", function(event, element, selectric) {
        isPersonaOpen = false;
      })
      .on("selectric-refresh", function(event, element, selectric) {
        //   console.log("selectric refresh");
        $(element)
          .closest(".selectric-wrapper")
          .find(".label")
          .fadeOut(0);
        // persona label fade out
        $(element)
          .closest(".c-form__select-wrapper")
          .find('.c-form__info')
          .fadeOut(0);

        $(element)
          .closest(".selectric-wrapper")
          .find(".label")
          .fadeIn(2000);
        // persona label fade in
        $(element).closest(".c-form__select-wrapper")
          .find('.c-form__info')
          .fadeIn(2000);
      });

    $(document).on("homepage:slider:change", function(event, sliderItem) {
      // console.log(sliderItem);
      $(".js-select-persona")
        .prop("selectedIndex", sliderItem)
        .selectric("refresh");
      if (isPersonaOpen) {
        $(".js-select-persona").selectric("open");
      }
      var topicToSelect = $(".js-select-persona")
        .find("option:selected")
        .data("selected");
      var indexOfTopic = parseInt(
        $(".js-select-topic").find('option[value="' + topicToSelect + '"]')[0]
          .index,
        10
      );
      $(".js-select-topic")
        .prop("selectedIndex", indexOfTopic)
        .selectric("refresh");
      if (isTopicOpen) {
        $(".js-select-topic").selectric("open");
      }
    });
  };

  function init() {
    //setupSelectBox();
    //updateInitialVal();
    setupBootstarpSelect();
  }

  return {
    init: init
  };
})();

export default customSelectBox;
